import classNames from "classnames";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "src/components/select";
import TimeWindowOption from "src/components/time-window-option";
import { DeliveryOptionData } from "src/constants/ke_cz";
import DatePicker from "src/components/date-picker";
import KeCzDesktopTimeItems from "src/models/ke-cz-desktop-time-items";
import OptionFactory from "../../../../models/factories/option-factory";
import { EXTRA_PRICE_KE_CZ } from "../../../../constants/price";
import classes from "./desctop-date-selector.module.scss";
import { momentToDate } from "src/helpers/additionalFuctions";
import {KE_CZ_TIME_ITEMS, LAST_DELIVEY_HOUR, today} from "../../../../constants/calendars";
import DeliveryTime from "../../../../models/delivery-time";
import {getTimeItemsKeCz} from "../../../../helpers/helpersFunctions";
import {getHours} from "../../../../helpers/additionalFuctions";

function DesktopDateSelector({
  errorNotChoosenTimePickup,
  deliveryLabel,
  deliveryTimeChange,
  deliveryDateChange,
  initialDay,
  isNotDeliveredToday,
  setAdditionalPrice,
  additionalPrice,
  setErrorNotChoosenTimePickup,
  filteringTimeWindows
}) {
  const [selectedDate, setSelectedDate] = useState({});
  const timeWindows = DeliveryOptionData.filter(
    (item) => {
      return !(item?.date?.isSame(today) && getHours() >= LAST_DELIVEY_HOUR);
    }
  ).map((data) => OptionFactory.create(data));
  const anotherTimeItems = getTimeItemsKeCz(!(!filteringTimeWindows || isNotDeliveredToday));

  useEffect(() => {}, [anotherTimeItems]);

  useEffect(() => {
    if (selectedDate.type === "custom") {
      deliveryDateChange(initialDay);
    }
  }, [selectedDate]) ;

  const _onChangeTime = (item) => {
    if (item.itemCost) {
      setAdditionalPrice({
        ...additionalPrice,
        extraTime: {
          active: true,
          extraprice: EXTRA_PRICE_KE_CZ,
        },
      });
    } else {
      setAdditionalPrice({
        ...additionalPrice,
        extraTime: {
          active: false,
        },
      });
    }

    deliveryTimeChange(
      new DeliveryTime({
        data: item.data,
        id: item.id,
      })
    );
    setErrorNotChoosenTimePickup("");
  };

  return (
    <>
      <h2 className={classes.header}>Kedy máme zásielku vyzdvihnúť?</h2>

      <Select
        className={classes.select}
        items={timeWindows}
        LabelComponent={TimeWindowOption}
        onChange={(item) => {
          setSelectedDate(item);
          deliveryDateChange(item.selectedDate);
        }}
        initValue={selectedDate}
      />

      {Object.values(selectedDate).length > 0 && (
        <div className={classes.dateWrapper}>
          {selectedDate.type === "custom" && (
            <div className={classes.dateSelectorWrapper}>
              <label htmlFor="deliveryDate">Zvoľte dátum</label>
              <DatePicker
                onChange={(item) => {
                  deliveryDateChange(item);
                }}
                defaultDay={momentToDate(initialDay)}
              />
            </div>
          )}
          <div className={classes.hourSelectorWrapper}>
            <label htmlFor="deliveryTime">Čas vyzdvihnutia a doručenia</label>
            <Select
              onChange={_onChangeTime}
              className={classes.hourSelector}
              items={anotherTimeItems}
            />
          </div>
        </div>
      )}

      {deliveryLabel && (
        <p
          className={classNames(classes.small, classes.selectedTimeWindowLabel)}
        >
          Zásielka bude doručená <strong>{deliveryLabel}</strong>
        </p>
      )}

      {errorNotChoosenTimePickup && (
        <p className={classes.errorNotTime}>{errorNotChoosenTimePickup}</p>
      )}
    </>
  );
}

export default DesktopDateSelector;
