import React from "react";
import TimeWindowOption from "src/components/time-window-option";

class CustomTimeWindowOption {

  constructor (data) {
      this.labelMessage = data.labelMessage;
      this.value = data.id;
      this.type = "custom";
  }

  get label() {
      return <TimeWindowOption>{this.labelMessage}</TimeWindowOption>
  }
}

export default CustomTimeWindowOption;