import React from "react";
import DesktopDateSelector from "./desktop-date-selector/desktop-date-selector";
import { useEffect, useState } from "react";
import MobileDateSelector from "./mobile-date-selector/mobile-date-selector";
import { useController, useFormContext } from "react-hook-form";
import {
  formatDate,
  getHours,
  getNextWeekDay,
  isItAWeekEndDay,
  getDayNumber,
  addDaysToDate,
} from "src/helpers/additionalFuctions";
import { breakPointsForKe_СZ } from "../../../constants/ke_cz";
import DeliveryTime from "../../../models/delivery-time";
import {
  today,
  MONDAY,
  TUESDAY,
  FRIDAY,
  SATURDAY,
  THURSDAY,
  LAST_DELIVEY_HOUR,
  LAST_PICKUP_HOUR,
  LAST_WORKING_HOUR,
  SUNDAY, isToday,
} from "../../../constants/calendars";
import {getEndDate} from "../../../helpers/helpersFunctions";

function DateSelector({
  errorNotChoosenTimePickup,
  setAdditionalPrice,
  additionalPrice,
  setErrorNotChoosenTimePickup,
}) {
  const { control } = useFormContext();
  const [width, setWidth] = useState(window.innerWidth);
  const [deliveryLabel, setDeliveryLabel] = useState("");
  const [isPickUpToday, setIsPickUpToday] = useState(false);
  const [isNotDeliveredToday, setIsNotDeliveredToday] = useState(getHours() >= LAST_DELIVEY_HOUR);
  const [filteringTimeWindows, setFilteringTimeWindows] = useState(true);
  const deliveryDate = useController({
    name: "deliveryDate",
    control,
    defaultValue: false,
  });

  const deliveryTime = useController({
    name: "deliveryTime",
    control,
    defaultValue: false,
  });



  useEffect(() => {
    if (deliveryDate.field.value && deliveryTime.field.value) {
      const date = getEndDate(deliveryDate.field.value, isPickUpToday);
      setDeliveryLabel(`${formatDate(date, "DD.MM.yy")}`);
    } else {
      setDeliveryLabel("");
    }
  }, [deliveryTime, deliveryDate]);

  const onChangeData = (item) => {
    deliveryTimeChange(
      new DeliveryTime({
        time: item.time,
        id: item.value,
        data: item.data,
        lable: deliveryLabel,
      })
    );
      deliveryDateChange(item.date);
  };

  const deliveryTimeChange = (date) => {
    setIsPickUpToday(date.data.hour <= LAST_PICKUP_HOUR);
    deliveryTime.field.onChange(date);
  };
  const deliveryDateChange = (date) => {
    setFilteringTimeWindows(isToday(date));
    setIsNotDeliveredToday(getHours() >= LAST_DELIVEY_HOUR);
    deliveryDate.field.onChange(date);
  };

  const getInitialDate = () => {
    if (
      isItAWeekEndDay() ||
      (getDayNumber() === FRIDAY && isNotDeliveredToday)
    ) {
      return getNextWeekDay(1);
    }
    if (getHours() > LAST_WORKING_HOUR - 1) {
      return addDaysToDate(1);
    }
    return today;
  };

  useEffect(() => {
    const listener = () => {
      setWidth(window.innerWidth);
    };
    addEventListener("resize", listener);

    return () => {
      removeEventListener("resize", listener);
    };
  }, []);

  return (
    <>
      {width > breakPointsForKe_СZ.tablet ? (
        <DesktopDateSelector
          deliveryLabel={deliveryLabel}
          deliveryDate={deliveryDate}
          deliveryTime={deliveryTime}
          deliveryTimeChange={deliveryTimeChange}
          deliveryDateChange={deliveryDateChange}
          initialDay={getInitialDate()}
          isNotDeliveredToday={isNotDeliveredToday}
          filteringTimeWindows={filteringTimeWindows}
          setAdditionalPrice={setAdditionalPrice}
          additionalPrice={additionalPrice}
          errorNotChoosenTimePickup={errorNotChoosenTimePickup}
          setErrorNotChoosenTimePickup={setErrorNotChoosenTimePickup}
        />
      ) : (
        <MobileDateSelector
          onChangeData={onChangeData}
          deliveryLabel={deliveryLabel}
          deliveryDate={deliveryDate.field.value}
          deliveryTime={deliveryTime.field.value}
          deliveryTimeChange={deliveryTimeChange}
          deliveryDateChange={deliveryDateChange}
          initialDay={getInitialDate()}
          isNotDeliveredToday={isNotDeliveredToday}
          filteringTimeWindows={filteringTimeWindows}
          setAdditionalPrice={setAdditionalPrice}
          additionalPrice={additionalPrice}
          errorNotChoosenTimePickup={errorNotChoosenTimePickup}
          setErrorNotChoosenTimePickup={setErrorNotChoosenTimePickup}
        />
      )}
    </>
  );
}

export default DateSelector;
