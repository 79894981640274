import { formatDate } from "src/helpers/additionalFuctions";
import TimeWindowOption from "../components/time-window-option";

class TimeOption {
    constructor (data) {
      if (data) {
        this.LabelComponent = TimeWindowOption;
        this.labelProps = data.labelProps;
        this.day = data.day;
        this.value = data.id;
        this.time = data?.time || "";
        this.selectedDate = data.date;
        this.cost = data.cost;
        this.timeData = data.timeData;
      }

    }

    get label() {
      return this.time
        ? `(${this.day}) ${this.date}, ${this.time}`
        : this.shortLabel;
    }

    get shortLabel() {
      return `(${this.day}) ${this.date}`;
    }

    get data() {

      return {
        date: this.selectedDate,
        time: {
          label: this.time,
          id: null,
          data: this.timeData
        },
        cost: this.cost,
      }
    }

    set date(date) {
      this.selectedDate = date;
    }

    get date() {

      return formatDate(this.selectedDate, "DD.MM.yy");
    }
}

export default TimeOption;