import React, { useState, useEffect } from "react";
import classNames from "classnames";
import TimeSchedule from "src/components/time-schedule/time-shedule";
import moment from "moment";
import Select from "../../../select";
import TimeWindowOption from "../../../time-window-option";
import OptionFactory from "../../../../models/factories/option-factory";
import { DeliveryMobileOptionData } from "../../../../constants/ke_cz";
import FormGroup from "../../../form-group";
import DatePicker from "../../../date-picker";
import { KE_CZ_TIME_ITEMS, today } from "../../../../constants/calendars";
import KeCzDesktopTimeItems from "src/models/ke-cz-desktop-time-items";
import DeliveryTime from "../../../../models/delivery-time";
import { momentToDate } from "src/helpers/additionalFuctions";
import classes from "../desktop-date-selector/desctop-date-selector.module.scss";
import { EXTRA_PRICE_KE_CZ } from "../../../../constants/price";
import {getTimeItemsKeCz} from "../../../../helpers/helpersFunctions";
function MobilDateSelector({
  deliveryDate,
  deliveryTimeChange,
  deliveryDateChange,
  initialDay,
  deliveryTime,
  deliveryLabel,
  isNotDeliveredToday,
  setAdditionalPrice,
  additionalPrice,
  setErrorNotChoosenTimePickup,
  errorNotChoosenTimePickup,
  filteringTimeWindows
}) {
  const timeWindows = DeliveryMobileOptionData.filter(
    (item) => !(item?.date?.isSame(today) && isNotDeliveredToday)
  ).map((data) => OptionFactory.create(data));
  const [dateItem, setDateItem] = useState({});
  useEffect(() => {
    if (dateItem.type === "custom") {
      deliveryDateChange(initialDay);
    }
  }, [dateItem]);

  const onTimeChange = (item) => {
    if (item.itemCost) {
      setAdditionalPrice({
        ...additionalPrice,
        extraTime: {
          active: true,
          extraprice: EXTRA_PRICE_KE_CZ,
        },
      });
    } else {
      setAdditionalPrice({
        ...additionalPrice,
        extraTime: {
          active: false,
        },
      });
    }
    deliveryTimeChange(
      new DeliveryTime({
        id: item.id,
        label: item.time,
        data: item.data,
      })
    );
    setErrorNotChoosenTimePickup("");
  };
  const timeItems = getTimeItemsKeCz(!(!filteringTimeWindows || isNotDeliveredToday));

  return (
    <>
      <h2 className={classes.header}>Kedy máme zásielku vyzdvihnúť?</h2>
      <Select
        className={classes.select}
        items={timeWindows}
        LabelComponent={TimeWindowOption}
        onChange={(item) => {
          setDateItem(item);
          const date = item.type === "custom" ? today : item.selectedDate;
          deliveryDateChange(date);
        }}
      />

      <FormGroup className={classes.itemWrap}>
        {dateItem.type === "custom" && (
          <div className={classes.selectDataWrap}>
            <span className={classes.selectDataTitle}>Vyberte dátum</span>
            <DatePicker
              onChange={(item) => {
                deliveryDateChange(moment(item));
              }}
              defaultDay={momentToDate(initialDay)}
            />
          </div>
        )}
        {deliveryDate && (
          <TimeSchedule
              isFiltered={isNotDeliveredToday}
              onTimeSelect={onTimeChange}
              items={timeItems}
              selectedItem={deliveryTime && deliveryTime.id}
              deliveryDate={deliveryDate}
          />
        )}
      </FormGroup>
      {deliveryLabel && (
        <p
          className={classNames(
            classes.smallMobile,
            classes.selectedTimeWindowLabel
          )}
        >
          Zásielka bude doručená {""} <strong>{deliveryLabel}</strong>
        </p>
      )}
      {errorNotChoosenTimePickup && (
        <p className={classes.errorMessageTime}>{errorNotChoosenTimePickup}</p>
      )}
    </>
  );
}

export default MobilDateSelector;
