import CustomTimeWindowOption from "../custom-time-option";
import TimeOption from "../time-option";

class OptionFactory {
  static create(data) {
    if (data.type === "custom") {

      return new CustomTimeWindowOption(data);
    }

    return new TimeOption(data);
  }
}


export default OptionFactory;