import React, { useEffect, useRef, useState } from "react";
import Page from "src/components/page";
import classNames from "classnames";
import Header from "src/components/header";
import PageTitle from "src/components/page-title";
import CardForm from "src/components/card-form";
import FormGroup from "src/components/form-group";
import Button from "src/components/button";
import Underline from "src/components/undreline";
import KeyKzCountryImage from "src/components/key-cz-country-image";
import DeliveryInfoSection from "src/components/delivery-info-section";
import Checkbox from "src/components/checkbox";
import Radio from "src/components/radio";
import {
  useForm,
  useController,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import InfoPackage from "../../components/info-package";
import CustomerAddress from "../../components/forms/customer-information/customer-information";
import AddresseeInformation from "../../components/forms/addressee-information/addressee-information";
import DateSelector from "../../components/forms/date-selector/date-selector";
import GoHome from "../../components/go-home/index";
import classes from "./ke-cz.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { navigate } from "gatsby";
import { ANIMATION_TYPE } from "../../constants/animation";
import Footer from "../../components/footer";
import {
  PAYMENT_TYPE_ERROR,
  TERMS_ERROR,
  NOT_CHOOSEN_TIME_PICKUP,
  ANY_ERROR,
  NOT_SENT_ORDER_BY_EMAIL,
} from "../../constants/error-messages";
import {
  BASE_PRICE_KE_CZ,
  ExtraPrice_Pripadkova_Zone,
  EXTRA_PRICE_KE_CZ,
  BASE_PRICE_KE_KE,
} from "../../constants/price";
import {
  _getFormatPrice,
  typeFormFromLocation,
  determineTimeDelivery,
  createAditionalPrice,
} from "src/helpers/additionalFuctions";
import { setLastCompantToStorage } from "../../services/las_company.service";
import {
  INFO_PACKAGE,
  KEY_ACCEPTED_COOKIES,
  PRIPADKOVA_ZONA,
} from "../../constants/homepage";
import { getAcceptedCookie } from "../../services/acceptedCookie";
import CookieBanner from "../../components/cookie-banner/index";
import { createOrder } from "../../services/orderService";
import {
  NOT_CHOOSEN_PICKUP_ADDRESS,
  NOT_CHOOSE_DELIVERY_ADDRESS,
} from "../../constants/error-messages";

const KeCzFormPage = ({ location }) => {
  const [isAcceptedCookie, setIsAcceptedCookie] = useState(
    getAcceptedCookie(KEY_ACCEPTED_COOKIES)
  );
  const [errorSendEmail, serErrorSendEmail] = useState("");
  let basePriceKe_Cz = BASE_PRICE_KE_CZ / 100;
  const [isChangedAddressCustomer, setIsChangedAddressCustomer] = useState(
    false
  );
  const [isChangedAddressAddresse, setIsChangedAddressAddresse] = useState(
    false
  );
  const [
    errorIsNotchoosenPickupAddress,
    setErrorIsNotchoosenPickupAddress,
  ] = useState("");
  const [
    errorisNotChoosenDeliveryAddress,
    setErrorisNotChoosenDeliveryAddress,
  ] = useState("");
  const dateSelectorRef = useRef(null);
  const pickupAddressCustomerRef = useRef(null);
  const deliveryAddressCustomerRef = useRef(null);
  const [isNeddedMultiply, setIsNeddedMultiply] = useState(true);
  const [additionalPrice, setAdditionalPrice] = useState({
    pripadkovaZonePickup: {
      active: false,
      extraprice: ExtraPrice_Pripadkova_Zone,
    },
    extraTime: {
      active: false,
      extraprice: EXTRA_PRICE_KE_CZ,
    },
  });
  const {
    register,
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
  } = useForm();
  const typeForm = typeFormFromLocation(location.pathname.replace("/", ""));

  const [errorNotChoosenTimePickup, setErrorNotChoosenTimePickup] = useState(
    ""
  );
  const getErrorMessage = () => {
    if (errors.paymentType) {
      return PAYMENT_TYPE_ERROR;
    } else if (errors.agreements) {
      return TERMS_ERROR;
    }
    return ANY_ERROR;
  };

  const Submit = async (values) => {
    if (!values.deliveryDate || !values.deliveryTime) {
      window.scrollTo({
        top: dateSelectorRef.current.offsetTop,
        behavior: "smooth",
      });
      setErrorNotChoosenTimePickup(NOT_CHOOSEN_TIME_PICKUP);
      return;
    }

    if (!pickupAddress() || !deliveryAddress()) {
      window.scrollTo({
        top: !pickupAddress()
          ? pickupAddressCustomerRef.current.offsetTop
          : deliveryAddressCustomerRef.current.offsetTop,
        behavior: "smooth",
      });
      !pickupAddress()
        ? setErrorIsNotchoosenPickupAddress(NOT_CHOOSEN_PICKUP_ADDRESS)
        : setErrorisNotChoosenDeliveryAddress(NOT_CHOOSE_DELIVERY_ADDRESS);
      return;
    }

    if (
      values.companyName &&
      getAcceptedCookie(KEY_ACCEPTED_COOKIES) !== false
    ) {
      const lastCompanyInformation = {
        companyName: values.companyName,
        companyZipCode: values.companyZipCode,
        companyTaxNumber: values.companyTaxNumber,
        companyRegistrationCode: values.companyRegistrationCode,
      };
      setLastCompantToStorage(lastCompanyInformation, typeForm);
    }
    try {
      const additinalDataOfOrder = {
        deliveryAddress: deliveryAddress(),
        pickupAddress: pickupAddress(),
        sum: _getFormatPrice(costSum(), true),
        additionalPrice: createAditionalPrice(additionalPrice),
      };
      const complitedOred = await createOrder(values, additinalDataOfOrder);
      await navigate("/thank-you", {
        state: complitedOred.complitedOrder,
      });
    } catch (error) {
      serErrorSendEmail(error);
    }
  };

  const delivery = BASE_PRICE_KE_CZ;

  const costSum = () => {
    let sum = basePriceKe_Cz;
    for (let key in additionalPrice) {
      if (additionalPrice[key].active) {
        sum = sum + additionalPrice[key].extraprice;
      }
    }
    return sum;
  };

  const pickupAddress = () => {
    if (!location.state || !location.state.pickupAddress) {
      return null;
    }
    return location.state.pickupAddress;
  };

  const deliveryAddress = () => {
    if (!location.state || !location.state.deliveryAddress) {
      return null;
    }
    return location.state.deliveryAddress;
  };
  useEffect(() => {
    if (deliveryAddress()) {
      setErrorisNotChoosenDeliveryAddress("");
    }

    if (pickupAddress) {
      setIsChangedAddressCustomer(false);
      setErrorIsNotchoosenPickupAddress("");
      let _pickupAddress = pickupAddress();
      if (
        _pickupAddress &&
        PRIPADKOVA_ZONA.includes(_pickupAddress.geoCode.postcode)
      ) {
        setAdditionalPrice({
          ...additionalPrice,
          pripadkovaZonePickup: {
            active: true,
            extraprice: ExtraPrice_Pripadkova_Zone,
          },
        });
      } else {
        setAdditionalPrice({
          ...additionalPrice,
          pripadkovaZonePickup: {
            active: false,
          },
        });
      }
    }
  }, [isChangedAddressCustomer, isChangedAddressAddresse]);

  useEffect(() => {
    AOS.init();
  }, []);

  const createNewRegisterForsubmitedValue = (listOfRegister = {}) => {
    for (const key in listOfRegister) {
      register(key);
      setValue(key, listOfRegister[key]);
    }
  };

  useEffect(() => {
    createNewRegisterForsubmitedValue({ typeForm: typeForm });
  }, []);
  return (
    <Page className={classes.page}>
      <div className={classes.container}>
        <div className={classes.containerHeader}>
          <Header hiddenContact />
        </div>
        <section className={classes.addSection}>
          <section className={classes.titleWrap}>
            <GoHome />
          </section>
          <section className={classes.titleWrap} data-aos={ANIMATION_TYPE}>
            <PageTitle
              page="form"
              title="Doručenie z Košíc do Českej republiky"
            />
          </section>

          <section className={classes.subSections} data-aos={ANIMATION_TYPE}>
            <FormProvider
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              getValues={getValues}
            >
              <form
                className={classes.formWrap}
                onSubmit={handleSubmit(Submit)}
                onKeyDown={(e) => {
                  {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }
                }}
              >
                <div className={classes.formPath}>
                  <InfoPackage infoPackage={INFO_PACKAGE.ke_cz} />

                  <CardForm
                    className={classNames(
                      classes.cardForm,
                      classes.timeWindowSection
                    )}
                  >
                    <div ref={dateSelectorRef}>
                      <DateSelector
                        setAdditionalPrice={setAdditionalPrice}
                        additionalPrice={additionalPrice}
                        control={control}
                        errorNotChoosenTimePickup={errorNotChoosenTimePickup}
                        setErrorNotChoosenTimePickup={
                          setErrorNotChoosenTimePickup
                        }
                      />
                    </div>
                  </CardForm>

                  <CardForm className={classes.cardForm}>
                    <div ref={pickupAddressCustomerRef}>
                      <CustomerAddress
                        register={register}
                        presetAddress={pickupAddress()}
                        location={location}
                        typeForm={typeForm}
                        setIsChangedAddressCustomer={
                          setIsChangedAddressCustomer
                        }
                        setAdditionalPrice={setAdditionalPrice}
                        additionalPrice={additionalPrice}
                        pripadkovaZonePickup={
                          additionalPrice.pripadkovaZonePickup.active
                        }
                        errors={errors}
                        errorIsNotchoosenPickupAddress={
                          errorIsNotchoosenPickupAddress
                        }
                        setErrorIsNotchoosenPickupAddress={
                          setErrorIsNotchoosenPickupAddress
                        }
                      />
                    </div>
                  </CardForm>

                  <CardForm className={classes.cardForm}>
                    <div ref={deliveryAddressCustomerRef}>
                      <AddresseeInformation
                        register={register}
                        presetAddress={deliveryAddress()}
                        setIsChangedAddressAddresse={
                          setIsChangedAddressAddresse
                        }
                        errors={errors}
                        typeForm={typeForm}
                        location={location}
                        errorisNotChoosenDeliveryAddress={
                          errorisNotChoosenDeliveryAddress
                        }
                        setErrorisNotChoosenDeliveryAddress={
                          setErrorisNotChoosenDeliveryAddress
                        }
                      />
                    </div>
                  </CardForm>

                  <CardForm className={classNames(classes.cardForm)}>
                    <FormGroup
                      className={classNames(
                        classes.formGroup,
                        classes.separator,
                        classes.paymentType
                      )}
                    >
                      <label htmlFor="paymentType">
                        Vyberte spôsob platby:
                      </label>
                      <Radio
                        register={register}
                        className={classes.radio}
                        id="paymentType1"
                        value="1"
                        name="paymentType"
                        errors={errors}
                      >
                        Kuriérovi pri vyzdvihnutí (kartou alebo v hotovosti)
                      </Radio>
                      <Radio
                        disabledFill
                        className={classes.radio}
                        id="paymentType2"
                        value="2"
                        name="paymentType"
                      >
                        <span className={classes.radio__disabled}>
                          Kuriérovi pri doručení (kartou alebo v hotovosti)
                        </span>
                      </Radio>
                    </FormGroup>
                    <span>
                      Doručenie Košice - Česká republika{" "}
                      {_getFormatPrice(delivery)} €
                    </span>

                    {additionalPrice.pripadkovaZonePickup.active ? (
                      <span>
                        <br />
                        Adresa v príplatkovej zóne +
                        {_getFormatPrice(
                          additionalPrice.pripadkovaZonePickup.extraprice,
                          isNeddedMultiply
                        )}
                        €
                      </span>
                    ) : (
                      ""
                    )}
                    <br />
                    {additionalPrice.extraTime.active ? (
                      <span>
                        Príplatok +
                        {_getFormatPrice(
                          additionalPrice.extraTime.extraprice,
                          isNeddedMultiply
                        )}
                        €
                      </span>
                    ) : (
                      ""
                    )}
                    <h2>
                      Spolu: {_getFormatPrice(costSum(), isNeddedMultiply)}€
                    </h2>
                  </CardForm>

                  <Checkbox
                    register={register}
                    className={classes.agreements}
                    id="agreements"
                    size="big"
                    errors={errors}
                  >
                    Súhlasím s{" "}
                    <Underline>
                      Obchodnými podmienkami{" "}
                      <span className={classes.mobileDisplayNone}>
                        Doruč.to
                      </span>
                    </Underline>
                  </Checkbox>
                  {Object.values(errors).length > 0 && (
                    <div className={classes.errorWrap}>{getErrorMessage()}</div>
                  )}
                  <p className={classes.errorSendEmail}>{errorSendEmail}</p>
                  <Button
                    type="submit"
                    className={classes.submitButton}
                    color="green"
                  >
                    Objednať
                  </Button>
                </div>
              </form>
            </FormProvider>
            <div className={classes.rightPath}>
              <div>
                <KeyKzCountryImage />
              </div>
              <div className={classes.deliverySection}>
                <DeliveryInfoSection braces="left" border />
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </div>
      {isAcceptedCookie === null ? (
        <CookieBanner setIsAcceptedCookie={setIsAcceptedCookie} />
      ) : (
        ""
      )}
    </Page>
  );
};

export default KeCzFormPage;
